<template>
    <v-card :loading="loading">
        <v-card-title class="text-h5">
            <v-btn
                class="mr-3"
                depressed
                color="primary"
                icon
                rounded
                @click="closeDialog"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>

            {{ title }}</v-card-title
        >
        <v-card-text>
            <v-form v-if="setting" ref="form" v-model="valid">
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            v-model="role.name"
                            :rules="[rules.required]"
                            label="Name *"
                            prepend-icon="mdi-shield-account"
                            required
                        />
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12">
                        <v-autocomplete
                            deletable-chips
                            :rules="[rules.required]"
                            v-model="role.permissions"
                            :items="permissions"
                            label="Permissions"
                            multiple
                            chips
                            prepend-icon="mdi-account-details"
                        ></v-autocomplete>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                text
                color="primary"
                @click="upload"
                :loading="loading"
                :disabled="!valid"
            >
                save
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import API from '@/services/api'
import _ from 'lodash'
export default {
    name: 'RoleForm',
    components: {},
    props: {
        setting: Object,
        title: String,
        value: Boolean,
        role: Object,
        createForm: Boolean,
    },
    data() {
        return {
            loading: false,
            valid: false,
            error: false,
            errorMsg: null,
            rules: {
                required: v => !!v || 'Required',
            },
            permissions: [],
            categories: [],
            originalRole: undefined,
        }
    },
    computed: {
        show: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            },
        },
    },
    mounted() {
        this.categories = Object.keys(this.setting.permissions)
        this.categories.forEach(key => {
            for (let item of this.setting.permissions[key]) {
                this.permissions.push(item.name)
            }
        })
        this.originalRole = _.cloneDeep(this.role)
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        closeDialog() {
            this.$emit('closeDialog')
            if (this.createForm) {
                this.$refs.form.reset()
            }
        },
        ...mapActions(['replaceSettingAction']),
        upload: async function() {
            try {
                if (!this.setting.roles) {
                    this.setting.roles = []
                }
                if (this.createForm) {
                    let permissions = {}
                    this.categories.map(key => {
                        for (let item of this.setting.permissions[key]) {
                            const index = this.role.permissions.findIndex(
                                p => p.toLowerCase() === item.name.toLowerCase()
                            )
                            if (index >= 0) {
                                if (!permissions[key]) {
                                    permissions[key] = []
                                }
                                permissions[key].push(item)
                                this.role.permissions.splice(index, 1)
                            }
                        }
                    })
                    this.setting.roles.push({
                        name: this.role.name,
                        permissions,
                    })
                } else {
                    let permissions = {}
                    this.categories.map(key => {
                        for (let item of this.setting.permissions[key]) {
                            const index = this.role.permissions.findIndex(
                                p => p.toLowerCase() === item.name.toLowerCase()
                            )
                            if (index >= 0) {
                                if (!permissions[key]) {
                                    permissions[key] = []
                                }
                                permissions[key].push(item)
                                this.role.permissions.splice(index, 1)
                            }
                        }
                    })
                    const newRole = { name: this.role.name, permissions }
                    const index = this.setting.roles.findIndex(
                        role =>
                            role.name.toLowerCase() ===
                            this.originalRole.name.toLowerCase()
                    )
                    this.setting.roles.splice(index, 1, newRole)
                }
                this.loading = true
                this.error = false
                this.errorMsg = null
                const newSetting = _.cloneDeep(this.setting)
                await API.updateSetting(this.setting.id, newSetting)
                this.replaceSettingAction(newSetting)
                this.$emit('refreshRoles')
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
                this.closeDialog()
            }
        },
    },
}
</script>
