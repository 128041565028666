<template>
    <v-container class="px-0 py-4" fluid>
        <v-data-table
            v-if="setting"
            hide-default-footer
            :headers="headers"
            color="grey lighten-3"
            :items="roles"
            :loading="loading"
            :mobile-breakpoint="0"
        >
            <template v-slot:top>
                <v-row class="d-flex justify-end pt-0">
                    <v-col> </v-col>

                    <v-col class="mt-n4 mb-3 text-right">
                        <v-btn
                            @click="addRole = true"
                            depressed
                            color="primary"
                            rounded
                        >
                            ADD ROLE
                        </v-btn>
                    </v-col>
                    <v-dialog
                        :retain-focus="false"
                        v-model="addRole"
                        persistent
                        max-width="600px"
                    >
                        <Role-Form
                            v-model="addRole"
                            @closeDialog="closeDialog"
                            @refreshRoles="refreshRoles"
                            title="Add Role"
                            :setting="setting"
                            :role="{}"
                            :createForm="true"
                        >
                        </Role-Form>
                    </v-dialog>
                </v-row>
            </template>
            <template v-slot:[`item.permissions`]="{ item }">
                <v-chip
                    class="mr-1 mb-1 mt-1"
                    small
                    v-for="(permission, i) in item.permissions"
                    :key="i"
                    >{{ permission }}</v-chip
                >
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <div class="d-flex">
                    <v-btn icon small @click="editRole(item)">
                        <v-icon small>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn icon small @click="deleteRole(item)">
                        <v-icon small>mdi-trash-can</v-icon>
                    </v-btn>
                    <v-dialog
                        :retain-focus="false"
                        v-model="editRoleDialog"
                        persistent
                        max-width="600px"
                    >
                        <Role-Form
                            v-if="editRoleDialog"
                            @closeDialog="closeDialog"
                            @refreshRoles="refreshRoles"
                            title="Edit Role"
                            :setting="setting"
                            :role="roleToEdit"
                            :createForm="false"
                        >
                        </Role-Form>
                    </v-dialog>
                </div>
            </template>
        </v-data-table>
        <v-dialog
            :retain-focus="false"
            v-model="deleteDialog"
            persistent
            max-width="400px"
        >
            <v-card :loading="loading">
                <v-card-title class="text-h5">Delete Role</v-card-title>
                <v-card-text>
                    Are you sure you want to delete this role?
                    <v-row v-if="error">
                        <v-col cols="12">
                            <v-alert
                                type="error"
                                dismissible
                                dense
                                class="ma-0"
                            >
                                {{ errorMsg }}
                            </v-alert>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-btn text color="secondary" @click="deleteDialog = false">
                        Close
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="deleteRolePermission"
                        text
                        color="error"
                        :loading="loading"
                    >
                        Delete
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import RoleForm from '@/components/Settings/RoleForm'
import _ from 'lodash'
import { mapActions, mapMutations } from 'vuex'
import API from '@/services/api'
export default {
    name: 'RolesPermissions',
    props: {
        setting: Object,
    },
    components: {
        RoleForm,
    },
    data() {
        return {
            loading: false,
            error: false,
            errorMsg: null,
            roleToEdit: {},
            roleToDelete: {},
            deleteError: false,
            deleteDialog: false,
            addRole: false,
            editRoleDialog: false,
            headers: [
                {
                    text: 'NAME',
                    value: 'name',
                    class: 'grey lighten-3',
                },
                {
                    text: 'PERMISSIONS',
                    value: 'permissions',
                    class: 'grey lighten-3',
                },
                {
                    text: 'ACTIONS',
                    value: 'actions',
                    class: 'grey lighten-3',
                    sortable: false,
                },
            ],
            roles: [],
            categories: [],
        }
    },
    mounted() {
        this.categories = Object.keys(this.setting.permissions)
        this.setting.roles.forEach(role => {
            this.roles.push(this.restructureRoles(role))
        })
    },
    methods: {
        refreshRoles() {
            this.roles = []
            this.setting.roles.forEach(role => {
                this.roles.push(this.restructureRoles(role))
            })
        },
        restructureRoles(role) {
            var permissions = []
            this.categories.forEach(key => {
                if (role.permissions[key]) {
                    for (let item of role.permissions[key]) {
                        permissions.push(item.name)
                    }
                }
            })
            return {
                name: role.name,
                permissions,
            }
        },
        closeDialog() {
            this.addRole = false
            this.editRoleDialog = false
        },
        editRole(item) {
            this.editRoleDialog = true
            this.roleToEdit = item
        },
        deleteRole(item) {
            this.deleteDialog = true
            this.roleToDelete = item
        },
        ...mapActions(['replaceSettingAction']),
        ...mapMutations(['setErrorItems']),
        deleteRolePermission() {
            const index = this.setting.roles.findIndex(
                x => x.name == this.roleToDelete.name
            )
            this.setting.roles.splice(index, 1)
            this.refreshRoles()
            this.upload()
        },
        upload: async function() {
            try {
                this.loading = true
                this.deleteError = false
                this.errorMsg = null
                const newSetting = _.cloneDeep(this.setting)
                await API.updateSetting(this.setting.id, newSetting)
                this.replaceSettingAction(newSetting)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
                this.deleteDialog = false
            }
        },
    },
}
</script>

<style scoped>
.v-data-table >>> table {
    border: thin solid #eeeeee;
}
</style>
